<template>
  <div
    v-if="loadData"
    class="page-static"
    :style="{ display: returnCheckView() }"
  >
    <div
      v-if="detailFixedPage.type == type_fixed['column_page']"
      class="pre-Column"
      :style="{
        'background-image': returnImg(
          `${detailFixedPage && detailFixedPage.background_image}`
        ),
        'background-color': `${
          detailFixedPage && detailFixedPage.background_color
        }`,
        'background-repeat': returnRepeat(
          `${detailFixedPage && detailFixedPage.dulipcate_background}`
        ),
        'background-attachment': returnFixed(
          `${detailFixedPage && detailFixedPage.fixed_background}`
        ),
        'background-position': returnPlace(
          `${detailFixedPage && detailFixedPage.position_background}`
        ),
      }"
    >
      <v-style>
        {{ detailFixedPage.css }}
      </v-style>
      <div class="header-content mb-3">
        <div class="container" style="min-height: 90vh">
          <div class="header-pre row">
            <img
              class="w-100"
              v-if="detailFixedPage && detailFixedPage.logo_image"
              :src="urlBackend + detailFixedPage.logo_image"
            />
          </div>
          <div
            class="content-pre ct-home row"
            :class="
              detailFixedPage &&
              (detailFixedPage.type_header == type_header_constant['frame'] ||
                detailFixedPage.type_header ==
                  type_header_constant['menu_frame'])
                ? 'content-frame'
                : ''
            "
            :style="{
              'background-image': returnImg(
                `${detailFixedPage && detailFixedPage.article_background_image}`
              ),
              'background-color': `${
                detailFixedPage && detailFixedPage.article_background_color
              }`,
              'background-repeat': returnRepeat(
                `${
                  detailFixedPage && detailFixedPage.article_background_repeat
                }`
              ),
              'background-attachment': returnFixed(
                `${detailFixedPage && detailFixedPage.article_background_fixed}`
              ),
              'background-position': returnPlace(
                `${
                  detailFixedPage && detailFixedPage.article_background_position
                }`
              ),
              '-webkit-box-shadow': returnShadow(
                `${detailFixedPage && detailFixedPage.shadow_color}`
              ),
            }"
          >
            <div
              class="author-content mt-3"
              :class="
                detailFixedPage &&
                detailFixedPage.type_header !=
                  type_header_constant['white_page']
                  ? 'margin-content w-100'
                  : 'w-100'
              "
            >
              <div class="card-content pt-3 row accordion-page">
                <div
                  class="ql-editor previewspage"
                  v-for="(value, key) in detailFixedPage &&
                  detailFixedPage.description"
                  style="width: 100%"
                  :key="key"
                >
                  <!-- <b-collapse visible accordion="my-accordion" role="tabpanel"> -->
                  <b-card-body class="px-0">
                    <div
                      class="w-100"
                      v-for="(itemBody, indexBody) in value.description.blocks"
                      :key="indexBody"
                      :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
                    >
                      <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'paragraph'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :style="{
                          lineHeight:
                            itemBody.data.lineHeight &&
                            itemBody.data.lineHeight !== null
                              ? itemBody.data.lineHeight
                              : '',
                        }"
                      >
                        <div v-html="itemBody.data.text"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'tableOfContents'"
                      >
                        <h3>目次</h3>
                        <ul class="list-unstyled pl-4 listTableOfContent">
                          <li
                            v-for="(itemTOC, index) in itemBody.data.items"
                            :key="index"
                            class="pl-3"
                          >
                            <a
                              style="cursor: pointer"
                              v-scroll-to="{
                                el: '#' + itemTOC.href,
                                offset: -20,
                              }"
                              v-html="itemTOC.text"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'header'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <h1
                          v-if="itemBody.data.level === 1"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h1>
                        <h2
                          v-if="itemBody.data.level === 2"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h2>
                        <h3
                          v-if="itemBody.data.level === 3"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h3>
                        <h4
                          v-if="itemBody.data.level === 4"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h4>
                        <h5
                          v-if="itemBody.data.level === 5"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h5>
                        <h6
                          v-if="itemBody.data.level === 6"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h6>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'list'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <ul class="w-100" style="list-style-type: decimal">
                          <li
                            v-for="(itemList, indexList) in itemBody.data.items"
                            :key="indexList"
                            class="mb-2"
                            v-html="itemList"
                          ></li>
                        </ul>
                      </div>
                      <div
                        class="item-data w-100"
                        v-if="itemBody.type === 'toggle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="w-100 d-flex align-items-center toggle-block__selector"
                          :id="itemBody.id"
                          :class="
                            itemBody.data.status === 'open'
                              ? 'toggle-open'
                              : 'toggle-close'
                          "
                        >
                          <span
                            class="icon-toggle-panel mr-2"
                            @click="toggleBlock(itemBody)"
                          >
                            <b-icon
                              :icon="
                                itemBody.data.status === 'open'
                                  ? 'x-lg'
                                  : 'plus-lg'
                              "
                            ></b-icon>
                          </span>
                          {{ itemBody.data.text }}
                        </div>
                      </div>
                      <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'code'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.code"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3 editor-raw"
                        v-if="itemBody.type === 'raw'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : ''
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.html"></div>
                        <!-- <code>{{ itemBody.data.html }}</code> -->
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'table'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                v-for="(itemTblTh, indexTblTh) in itemBody.data
                                  .content[0]"
                                :key="indexTblTh"
                                class="border-bottom-0"
                              >
                                <span class="w-100" v-html="itemTblTh"></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(itemTblTr, indexTblTr) in itemBody.data
                                .contentTbody"
                              :key="indexTblTr"
                            >
                              <td
                                v-for="(itemTblTd, indexTblTd) in itemTblTr"
                                :key="indexTblTd"
                              >
                                <span class="w-100" v-html="itemTblTd"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'checklist'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="custom-control custom-checkbox"
                          v-for="(itemCheckbox, indexCheckbox) in itemBody.data
                            .items"
                          :key="indexCheckbox"
                        >
                          <input
                            :id="'checkbox-' + itemBody.id + indexCheckbox"
                            type="checkbox"
                            :name="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-input"
                            :checked="itemCheckbox.checked"
                          />
                          <label
                            :for="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-label ml-1"
                          >
                            <span v-html="itemCheckbox.text"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'warning'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 bg-warning p-3 text-white">
                          <div class="w-100 font-weight-bold">
                            {{ itemBody.data.title }}
                          </div>
                          <hr />
                          <div class="w-100">
                            {{ itemBody.data.message }}
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'quote'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <blockquote
                          class="otro-blockquote"
                          :class="
                            itemBody.data.alignment === 'left'
                              ? 'text-left'
                              : 'text-right'
                          "
                        >
                          <span>
                            {{ itemBody.data.caption }}
                          </span>
                          <span v-html="itemBody.data.text"></span>
                        </blockquote>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'headerStyle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="titleStyle" :class="itemBody.data.class">
                          <h1 class="dataInput">
                            {{ itemBody.data.text }}
                          </h1>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'boxStylle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="boxStyle" :class="itemBody.data.class">
                          <span
                            class="box-title px-2 border-0"
                            v-if="itemBody.data.title"
                            >{{ itemBody.data.title }}</span
                          >
                          <div
                            class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                          >
                            {{ itemBody.data.text }}
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'delimiter'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 text-center ce-delimiter"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'image'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <a
                          v-if="replaceText(itemBody.data.linkImage)"
                          :href="itemBody.data.linkImage"
                        >
                          <img
                            :src="itemBody.data.file.url"
                            alt=""
                            width="auto"
                          />
                        </a>
                        <img
                          v-else
                          :src="itemBody.data.file.url"
                          alt=""
                          width="auto"
                        />
                        <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'attachesPDF'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <!-- <ViewPdf
                          v-bind:pageNumber="lastPagePDF"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:idLast="idLastPDF"
                          v-bind:src="itemBody.data.file.url"
                          v-bind:idComponent="itemBody.id"
                        /> -->
                        <b-button
                          variant="primary"
                          class="btn-preview-fixed mr-0"
                          name="btn-view-PDF"
                          v-on:click.prevent="
                            viewFilePDF(itemBody.data.file, itemBody.id)
                          "
                        >
                          {{ itemBody.data.title }}
                        </b-button>
                      </div>
                      <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'youtube'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <YoutubePlayer
                          v-bind:idElemYoutube="itemBody.id"
                          v-bind:idLastYoutube="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.source"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'vimeo'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <VimeoPlayer
                          v-bind:idElemVimeo="itemBody.id"
                          v-bind:idLastVimeo="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.embed"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'anyButton'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                        <a
                          v-if="itemBody.data.type === null"
                          class="anyButton"
                          :class="itemBody.data.style"
                          @click="downloadFile($event, itemBody.data)"
                        >
                          {{ itemBody.data.text }}
                        </a>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'roundButton' ||
                              itemBody.data.style === 'socialGlossySmooth' ||
                              itemBody.data.style === 'socialSquare')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <a
                            v-if="itemBody.data.twitter !== null"
                            :href="itemBody.data.twitter"
                            class="btn-social-circle btn-social-circle--twitter"
                            ><i class="fab fa-twitter"></i>
                          </a>
                          <a
                            v-if="itemBody.data.facebook !== null"
                            :href="itemBody.data.facebook"
                            class="btn-social-circle btn-social-circle--facebook"
                            ><i class="fab fa-facebook-f"></i>
                          </a>
                          <a
                            v-if="itemBody.data.pocket !== null"
                            :href="itemBody.data.pocket"
                            class="btn-social-circle btn-social-circle--pocket"
                            ><i class="fab fa-get-pocket"></i>
                          </a>
                          <a
                            v-if="itemBody.data.feedly !== null"
                            :href="itemBody.data.feedly"
                            class="btn-social-circle btn-social-circle--feedly"
                            ><i class="fas fa-rss"></i
                          ></a>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialGiza' ||
                              itemBody.data.style === 'socialSmartPhone')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza'
                                : 'btn-social-phone'
                            "
                          >
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-twitter'
                                  : 'btn-social-phone-twitter ml-0 mr-3'
                              "
                              v-if="itemBody.data.twitter !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="3535"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-facebook'
                                  : 'btn-social-phone-facebook ml-0 mr-3'
                              "
                              v-if="itemBody.data.facebook !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="5467"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-pocket'
                                  : 'btn-social-phone-pocket ml-0 mr-3'
                              "
                              v-if="itemBody.data.pocket !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-get-pocket fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-feedly'
                                  : 'btn-social-phone-feedly ml-0 mr-3'
                              "
                              v-if="itemBody.data.feedly !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fas fa-rss fa-stack-1x"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialFlat' ||
                              itemBody.data.style === 'socialIsometric')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody, value.description.blocks)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                                "
                              >
                                <i class="fab fa-twitter"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                                "
                              >
                                <i class="fab fa-facebook"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                                "
                              >
                                <i class="fab fa-get-pocket"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                                "
                              >
                                <i class="fas fa-rss"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3"
                        v-if="itemBody.type === 'attachesFile'"
                      >
                        <div class="w-100 d-flex box-file-upload">
                          <div
                            class="iconFile"
                            :data-extension="itemBody.data.file.extension"
                            :style="{ color: itemBody.data.file.color }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="40"
                            >
                              <path
                                d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                              />
                            </svg>
                          </div>
                          <div class="fileName">
                            <div class="w-100">
                              {{ itemBody.data.file.name }}
                            </div>
                            <div
                              class="text-muted w-100"
                              v-html="convertSize(itemBody.data.file.size)"
                            ></div>
                          </div>
                          <div class="iconDowload ml-auto">
                            <a
                              class="iconDowload"
                              v-bind:download="itemBody.data.file.url"
                              rel="nofollow noindex noreferrer"
                              @click="
                                downloadFileUpload($event, itemBody.data.file)
                              "
                            >
                              <i class="fas fa-cloud-download-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                  <!-- </b-collapse> -->
                </div>
              </div>
              <!--<div
                class="card-content pt-3 row align-items-center justify-content-center mb-3"
              >
                <button
                  v-on:click="returnPage()"
                  class="float-right button-back-dashboard back-btn"
                >
                  戻る
                </button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <Footer />
      </div> -->
    </div>
    <div class="lineHeight ct-home" v-else>
      <div>
        <v-style>
          {{
            detailFixedPage.type == type_fixed["input_free"] &&
            detailFixedPage.css
          }}
        </v-style>
        <div class="page-preview ct-home">
          <div class="container page-content position-relative">
            <div class="card-content pt-3 row accordion-page">
              <div
                class="ql-editor previewspage"
                v-for="(value, key) in detailFixedPage.description"
                style="width: 100%"
                :key="key"
              >
                <!-- <b-collapse visible accordion="my-accordion" role="tabpanel"> -->
                <b-card-body class="px-0">
                  <div
                    class="w-100"
                    v-for="(itemBody, indexBody) in value.description.blocks"
                    :key="indexBody"
                    :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
                  >
                    <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'paragraph'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                      :style="{
                        lineHeight:
                          itemBody.data.lineHeight &&
                          itemBody.data.lineHeight !== null
                            ? itemBody.data.lineHeight
                            : '',
                      }"
                    >
                      <div v-html="itemBody.data.text"></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'tableOfContents'"
                    >
                      <h3>目次</h3>
                      <ul class="list-unstyled pl-4 listTableOfContent">
                        <li
                          v-for="(itemTOC, index) in itemBody.data.items"
                          :key="index"
                          class="pl-3"
                        >
                          <a
                            style="cursor: pointer"
                            v-scroll-to="{
                              el: '#' + itemTOC.href,
                              offset: -20,
                            }"
                            v-html="itemTOC.text"
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'header'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <h1
                        v-if="itemBody.data.level === 1"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h1>
                      <h2
                        v-if="itemBody.data.level === 2"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h2>
                      <h3
                        v-if="itemBody.data.level === 3"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h3>
                      <h4
                        v-if="itemBody.data.level === 4"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h4>
                      <h5
                        v-if="itemBody.data.level === 5"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h5>
                      <h6
                        v-if="itemBody.data.level === 6"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h6>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'list'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <ul class="w-100" style="list-style-type: decimal">
                        <li
                          v-for="(itemList, indexList) in itemBody.data.items"
                          :key="indexList"
                          class="mb-2"
                          v-html="itemList"
                        ></li>
                      </ul>
                    </div>
                    <div
                      class="item-data w-100"
                      v-if="itemBody.type === 'toggle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div
                        class="w-100 d-flex align-items-center toggle-block__selector"
                        :id="itemBody.id"
                        :class="
                          itemBody.data.status === 'open'
                            ? 'toggle-open'
                            : 'toggle-close'
                        "
                      >
                        <span
                          class="icon-toggle-panel mr-2"
                          @click="toggleBlock(itemBody)"
                        >
                          <b-icon
                            :icon="
                              itemBody.data.status === 'open'
                                ? 'x-lg'
                                : 'plus-lg'
                            "
                          ></b-icon>
                        </span>
                        {{ itemBody.data.text }}
                      </div>
                    </div>
                    <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'code'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div v-html="itemBody.data.code"></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100 my-3 raw-input"
                      v-if="itemBody.type === 'raw'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : ''
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div v-html="itemBody.data.html"></div>
                      <!-- <code>{{ itemBody.data.html }}</code> -->
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'table'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              v-for="(itemTblTh, indexTblTh) in itemBody.data
                                .content[0]"
                              :key="indexTblTh"
                              class="border-bottom-0"
                            >
                              <span class="w-100" v-html="itemTblTh"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(itemTblTr, indexTblTr) in itemBody.data
                              .contentTbody"
                            :key="indexTblTr"
                          >
                            <td
                              v-for="(itemTblTd, indexTblTd) in itemTblTr"
                              :key="indexTblTd"
                            >
                              <span class="w-100" v-html="itemTblTd"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'checklist'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div
                        class="custom-control custom-checkbox"
                        v-for="(itemCheckbox, indexCheckbox) in itemBody.data
                          .items"
                        :key="indexCheckbox"
                      >
                        <input
                          :id="'checkbox-' + itemBody.id + indexCheckbox"
                          type="checkbox"
                          :name="'checkbox-' + itemBody.id + indexCheckbox"
                          class="custom-control-input"
                          :checked="itemCheckbox.checked"
                        />
                        <label
                          :for="'checkbox-' + itemBody.id + indexCheckbox"
                          class="custom-control-label ml-1"
                        >
                          <span v-html="itemCheckbox.text"></span>
                        </label>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'warning'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div class="w-100 bg-warning p-3 text-white">
                        <div class="w-100 font-weight-bold">
                          {{ itemBody.data.title }}
                        </div>
                        <hr />
                        <div class="w-100">
                          {{ itemBody.data.message }}
                        </div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'quote'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <blockquote
                        class="otro-blockquote"
                        :class="
                          itemBody.data.alignment === 'left'
                            ? 'text-left'
                            : 'text-right'
                        "
                      >
                        <span>
                          {{ itemBody.data.caption }}
                        </span>
                        <span v-html="itemBody.data.text"></span>
                      </blockquote>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'headerStyle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                    >
                      <div class="titleStyle" :class="itemBody.data.class">
                        <h1 class="dataInput">
                          {{ itemBody.data.text }}
                        </h1>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'boxStylle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                    >
                      <div class="boxStyle" :class="itemBody.data.class">
                        <span
                          class="box-title px-2 border-0"
                          v-if="itemBody.data.title"
                          >{{ itemBody.data.title }}</span
                        >
                        <div
                          class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                        >
                          {{ itemBody.data.text }}
                        </div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'delimiter'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div class="w-100 text-center ce-delimiter"></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'image'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <a
                        v-if="replaceText(itemBody.data.linkImage)"
                        :href="itemBody.data.linkImage"
                      >
                        <img
                          :src="itemBody.data.file.url"
                          alt=""
                          width="auto"
                        />
                      </a>
                      <img
                        v-else
                        :src="itemBody.data.file.url"
                        alt=""
                        width="auto"
                      />
                      <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'attachesPDF'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <!-- <ViewPdf
                        v-bind:pageNumber="lastPagePDF"
                        v-bind:timeWatching="timeWatchingEmbed"
                        v-bind:idLast="idLastPDF"
                        v-bind:src="itemBody.data.file.url"
                        v-bind:idComponent="itemBody.id"
                      /> -->
                      <b-button
                        variant="primary"
                        class="btn-preview-fixed mr-0"
                        name="btn-view-PDF"
                        v-on:click.prevent="
                          viewFilePDF(itemBody.data.file, itemBody.id)
                        "
                      >
                        {{ itemBody.data.title }}
                      </b-button>
                    </div>
                    <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      v-if="
                        itemBody.type === 'embed' &&
                        itemBody.data.service === 'youtube'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <YoutubePlayer
                        v-bind:idElemYoutube="itemBody.id"
                        v-bind:idLastYoutube="idLastEmbed"
                        v-bind:timeWatching="timeWatchingEmbed"
                        v-bind:src="itemBody.data.source"
                        v-bind:heightPlayer="itemBody.data.height"
                        v-bind:widthPlayer="itemBody.data.width"
                        v-bind:dataVideo="itemBody.data"
                      />
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      v-if="
                        itemBody.type === 'embed' &&
                        itemBody.data.service === 'vimeo'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <VimeoPlayer
                        v-bind:idElemVimeo="itemBody.id"
                        v-bind:idLastVimeo="idLastEmbed"
                        v-bind:timeWatching="timeWatchingEmbed"
                        v-bind:src="itemBody.data.embed"
                        v-bind:heightPlayer="itemBody.data.height"
                        v-bind:widthPlayer="itemBody.data.width"
                        v-bind:dataVideo="itemBody.data"
                      />
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'anyButton'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                      <a
                        v-if="itemBody.data.type === null"
                        class="anyButton"
                        :class="itemBody.data.style"
                        @click="downloadFile($event, itemBody.data)"
                      >
                        {{ itemBody.data.text }}
                      </a>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'roundButton' ||
                            itemBody.data.style === 'socialGlossySmooth' ||
                            itemBody.data.style === 'socialSquare')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <a
                          v-if="itemBody.data.twitter !== null"
                          :href="itemBody.data.twitter"
                          class="btn-social-circle btn-social-circle--twitter"
                          ><i class="fab fa-twitter"></i>
                        </a>
                        <a
                          v-if="itemBody.data.facebook !== null"
                          :href="itemBody.data.facebook"
                          class="btn-social-circle btn-social-circle--facebook"
                          ><i class="fab fa-facebook-f"></i>
                        </a>
                        <a
                          v-if="itemBody.data.pocket !== null"
                          :href="itemBody.data.pocket"
                          class="btn-social-circle btn-social-circle--pocket"
                          ><i class="fab fa-get-pocket"></i>
                        </a>
                        <a
                          v-if="itemBody.data.feedly !== null"
                          :href="itemBody.data.feedly"
                          class="btn-social-circle btn-social-circle--feedly"
                          ><i class="fas fa-rss"></i
                        ></a>
                      </div>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'socialGiza' ||
                            itemBody.data.style === 'socialSmartPhone')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza'
                              : 'btn-social-phone'
                          "
                        >
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-twitter'
                                : 'btn-social-phone-twitter ml-0 mr-3'
                            "
                            v-if="itemBody.data.twitter !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-twitter fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="3535"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-facebook'
                                : 'btn-social-phone-facebook ml-0 mr-3'
                            "
                            v-if="itemBody.data.facebook !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-facebook-f fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="5467"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-pocket'
                                : 'btn-social-phone-pocket ml-0 mr-3'
                            "
                            v-if="itemBody.data.pocket !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-get-pocket fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-feedly'
                                : 'btn-social-phone-feedly ml-0 mr-3'
                            "
                            v-if="itemBody.data.feedly !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fas fa-rss fa-stack-1x"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'socialFlat' ||
                            itemBody.data.style === 'socialIsometric')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                              "
                            >
                              <i class="fab fa-twitter"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                              "
                            >
                              <i class="fab fa-facebook"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                              "
                            >
                              <i class="fab fa-get-pocket"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                              "
                            >
                              <i class="fas fa-rss"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100 my-3"
                      v-if="itemBody.type === 'attachesFile'"
                    >
                      <div class="w-100 d-flex box-file-upload">
                        <div
                          class="iconFile"
                          :data-extension="itemBody.data.file.extension"
                          :style="{ color: itemBody.data.file.color }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="40"
                          >
                            <path
                              d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                            />
                          </svg>
                        </div>
                        <div class="fileName">
                          <div class="w-100">{{ itemBody.data.file.name }}</div>
                          <div
                            class="text-muted w-100"
                            v-html="convertSize(itemBody.data.file.size)"
                          ></div>
                        </div>
                        <div class="iconDowload ml-auto">
                          <a
                            class="iconDowload"
                            v-bind:download="itemBody.data.file.url"
                            rel="nofollow noindex noreferrer"
                            @click="
                              downloadFileUpload($event, itemBody.data.file)
                            "
                          >
                            <i class="fas fa-cloud-download-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <!-- </b-collapse> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-detail-pdf"
      hide-footer
      modal-class="modal-fullscreen"
      v-model="modalShow"
    >
      <ViewPdf
        v-bind:src="filePDFView && filePDFView.url"
        v-bind:idComponent="filePDFView && filePDFView.idComponent"
        v-bind:dataInfo="filePDFView"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import no_image from "@/assets/img/no-image.jpg";
import { Constants } from "../../utils/constants.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Vue from "vue";
import ViewPdf from "../../components/viewPdf/index.vue";
// import ViewPdf from "../../components/viewPdf/pdfView.vue";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import { getIdFromUrl } from "vue-youtube";
import Player from "@vimeo/player";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

function initialState() {
  return {
    urlBackend: process.env.VUE_APP_ROOT_BACKEND + "/",
    no_image: no_image,
    page: null,
    pageNot: {
      title: "",
      description: [
        {
          description:
            '<p class="ql-align-center"><strong><span class="ql-cursor"></span>このページは非公開になっています。</strong></p>',
        },
      ],
    },
    today: new Date(),
    check: true,
    dataShop: [],
    homepage: window.location.origin,
    shop_id: localStorage.getItem(Constants.SHOP_ID),
    title: null,
    description: null,
    checked: null,
    loading: document.getElementsByClassName("loading-screen"),
    type_fixed: Constants.FIXED_PAGE_TYPE,
    optionColumn: null,
    loadData: false,
    type_header_constant: Constants.HEADER_FIXED_PAGE_TYPE,
    idLastEmbed: null,
    timeWatchingEmbed: 0,
    idLastPDF: null,
    lastPagePDF: null,
    statusYT: {
      playing: 1,
      paused: 2,
      ended: 0,
    },
    statusClick: {
      start: 0,
      paused: 1,
      end: 2,
    },
    dynamicTime: {},
    dynamicTimeVimeo: {},
    filePDFView: null,
    modalShow: false,
  };
}

export default {
  name: "staticPage",
  components: {
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return initialState();
  },
  async created() {
    // if (this.page == null && this.$route.fullPath.includes("fixed-page/")) {
    //   this.loading[0].style.display = "flex";
    // }
    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    if (
      this.$route.fullPath.includes("fixed-page/") &&
      !this.$route.fullPath.includes("admin/fixed-page/")
    ) {
      var tag = document.createElement("script");
      tag.id = "iframe-youtube";
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      await this.getFixedPageUser(reqData);
      var listRaw = document.getElementsByClassName("editor-raw");
      if (listRaw && listRaw.length > 0) {
        listRaw.forEach((raw) => {
          var listIframe = raw.getElementsByTagName("iframe");
          if (listIframe && listIframe.length > 0) {
            listIframe.forEach((ifMedia) => {
              if (ifMedia.src.includes("youtube.com")) {
                var videoId = getIdFromUrl(ifMedia.src);
                var id = raw.id + "_" + videoId + "_iframe_yt";
                ifMedia.outerHTML = `<div id='` + id + `'></div>`;
                this.settingPlayerYoutube(id, videoId);
                let name = "time_yt_" + videoId;
                this.dynamicTime[name] = null;
              } else if (ifMedia.src.includes("vimeo.com")) {
                var player = new Player(ifMedia);
                const selfVimeo = this;
                var vimeoId = selfVimeo.getVideoIDVimeo(ifMedia.src);
                let name = "time_vimeo_" + vimeoId;
                this.dynamicTimeVimeo[name] = null;
                player.on("play", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["start"],
                    player,
                    vimeoId
                  );
                });
                player.on("pause", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["paused"],
                    player,
                    vimeoId
                  );
                });
                player.on("ended", function () {
                  selfVimeo.eventVimeoData(
                    selfVimeo.statusClick["end"],
                    player,
                    vimeoId
                  );
                });
              }
            });
          }
        });
      }
    }
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    detailFixedPage() {
      if (this.detailFixedPage) {
        this.loadData = true;
        const token = localStorage.getItem(Constants.TOKEN_USER)
          ? localStorage.getItem(Constants.TOKEN_USER)
          : "";
        // check login
        if (this.detailFixedPage.is_login) {
          if (!token) {
            this.$router.push({
              name: this.$route.params.shopId
                ? "login shop"
                : "login shop domain",
              params: { shopId: this.$route.params.shopId },
            });
          }
        }
        // check redirect
        if (this.detailFixedPage.is_public == 0) {
          this.$router.push({
            name: this.$route.params.shopId ? "library" : "library domain",
            params: { shopId: this.$route.params.shopId },
          });
          return;
        }
        if (this.detailFixedPage.is_public == 1) {
          let checkRedirect = false;
          if (
            this.detailFixedPage.start_access_date &&
            this.today <
              new Date(
                this.detailFixedPage.start_access_date.replace(/-/g, "/")
              )
          ) {
            checkRedirect = true;
          }

          if (
            this.detailFixedPage.end_access_date &&
            this.today >
              new Date(this.detailFixedPage.end_access_date.replace(/-/g, "/"))
          ) {
            checkRedirect = true;
          }

          if (checkRedirect) {
            if (this.detailFixedPage.url_access_redirect) {
              window.location.href = this.detailFixedPage.url_access_redirect;
            } else {
              this.$router.push({
                name: this.$route.params.shopId ? "library" : "library domain",
                params: { shopId: this.$route.params.shopId },
              });
            }
            return;
          }
        }

        if (token) {
          // add infor of fixed page
          var inputs = localStorage.getItem(Constants.INFOR_ORDER);
          if (inputs) {
            inputs = JSON.parse(inputs);
            Object.keys(inputs).forEach((key) => {
              var value = inputs[key];
              const regex = new RegExp("{%" + key + "%}", "g"); // add this regex
              this.detailFixedPage.description.forEach((fp) => {
                // eslint-disable-next-line no-unused-vars
                fp.description.blocks.forEach((item) => {
                  if (item.data && item.data.text)
                    item.data.text = item.data.text.replace(regex, value);

                  if (
                    item.data &&
                    item.data.content &&
                    item.data.content.length > 0
                  ) {
                    item.data.content.forEach((itemContent) => {
                      if (itemContent.length > 0) {
                        itemContent.forEach((it, index) => {
                          itemContent[index] = it.replace(regex, value);
                        });
                      }
                    });
                  }
                });
              });
            });
          }

          var inforPast =
            localStorage.getItem(Constants.NORMAL_USER_INFO) &&
            JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO));
          inforPast["name"] = inforPast["last_name"] + inforPast["first_name"];
          Object.keys(inforPast).forEach((key) => {
            var value = inforPast[key];
            const regex = new RegExp("{%" + key + "%}", "g"); // add this regex
            this.detailFixedPage.description.forEach((fp) => {
              // eslint-disable-next-line no-unused-vars
              fp.description.blocks.forEach((item) => {
                if (item.data && item.data.text)
                  item.data.text = item.data.text.replace(regex, value);
              });
            });
          });
        }
        this.page = this.detailFixedPage;
        let externalScript = document.createElement("script");
        externalScript.innerHTML = this.detailFixedPage.js;
        document.head.appendChild(externalScript);

        if (this.detailFixedPage.type === 4) {
          this.title = this.detailFixedPage.title;
        }
        if (
          this.detailFixedPage.type === this.type_fixed["column_page"] &&
          this.$route.fullPath.includes("fixed-page/")
        ) {
          this.$store.commit("set", ["isShowLayout", true]);
        }
        if (
          this.detailFixedPage.type_header ===
            Constants.HEADER_FIXED_PAGE_TYPE["menu_frame"] ||
          this.detailFixedPage.type_header ===
            Constants.HEADER_FIXED_PAGE_TYPE["menu_no_frame"]
        ) {
          this.$store.commit("set", ["isShowMenu", ""]);
        } else {
          this.$store.commit("set", ["isShowMenu", "none"]);
        }
        this.detailFixedPage.description.map((item) => {
          const blocksData = item.description.blocks;
          blocksData.map((itemBlock) => {
            if (itemBlock.type === "attachesPDF") {
              itemBlock.data.file["view"] = null;
              itemBlock.data.file["idComponent"] = item.id;
            }
            if (itemBlock.type === "table") {
              itemBlock.data.contentTbody = itemBlock.data.content.filter(
                (itemBlock, index) => index > 0
              );
              return itemBlock;
            }
            if (itemBlock.type === "toggle") {
              itemBlock.data.status = "closed";
              const indexBlockToggle = blocksData.indexOf(itemBlock);
              if (itemBlock.data.child.length >= itemBlock.data.items) return;
              for (let i = 1; i <= itemBlock.data.items; i++) {
                itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
                if (itemBlock.data.status === "closed")
                  blocksData[indexBlockToggle + i].showDisplay = true;
                blocksData[indexBlockToggle + i].idChild = itemBlock.id;
              }
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "detailFixedPage",
      "error",
      "success",
      "message",
      "isShowLayout",
      "isShowMenu",
      "refer_url",
      "clickOnPDF",
    ]),
  },
  methods: {
    ...mapActions({ getFixedPageUser: "getFixedPageUser" }),
    clickButton(index) {
      this.$root.$emit("bv::toggle::collapse", "accordion" + index);
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    returnPage() {
      this.$router.go(-1);
      this.$store.commit("set", ["isShowLayout", false]);
      Object.assign(this.$data, initialState());
    },
    returnRepeat(type) {
      if (type == 0) {
        return "repeat";
      } else if (type == 1) {
        return "repeat-x";
      } else if (type == 2) {
        return "repeat-y";
      } else {
        return "no-repeat";
      }
    },
    returnFixed(type) {
      if (type == 0) {
        return "fixed";
      } else {
        return "scroll";
      }
    },
    returnPlace(type) {
      if (type == 0) {
        return "right";
      } else if (type == 1) {
        return "center";
      } else {
        return "left";
      }
    },
    returnShadow(color) {
      if (
        this.detailFixedPage &&
        this.detailFixedPage.type_header !=
          Constants.HEADER_FIXED_PAGE_TYPE["white_page"] &&
        this.detailFixedPage.type_header !=
          Constants.HEADER_FIXED_PAGE_TYPE["menu_no_frame"] &&
        this.detailFixedPage.article_background_shadow == 0
      ) {
        return "0 0 8px " + color;
      } else {
        return "none";
      }
    },
    returnImg(url) {
      if (url) {
        return "url(" + this.urlBackend + url + ")";
      } else return "";
    },
    // toggleBlock(item) {
    //   const value = item.data.status === "closed";
    //   const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
    //   const { length } = children;

    //   if (length > 0) {
    //     for (let i = 0; i < length; i++) {
    //       children[i].hidden = !value;
    //       if (children[i].showDisplay !== undefined)
    //         children[i].showDisplay = !value;
    //     }
    //   }
    //   if (item.data.status === "open") {
    //     item.data.status = "closed";
    //   } else {
    //     item.data.status = "open";
    //   }
    // },
    returnCheckView() {
      if (this.$route.fullPath.includes("admin/fixed-page/pre_column_page")) {
        return "none";
      } else {
        return "";
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkIndex(toggle, list) {
      var listToggle = list.filter((item) => item.idChild === toggle.idChild);
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },

    settingPlayerYoutube(id, video_Id) {
      const check = this;
      // eslint-disable-next-line no-undef
      new YT.Player(id, {
        // height: "390",
        // width: "640",
        videoId: video_Id,
        events: {
          onReady: check.onPlayerReady,
          onStateChange: check.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      let inforVideo = event.target.playerInfo;
      let videoID = inforVideo.videoData.video_id;
      let name = "time_yt_" + videoID;
      let videoTitle = inforVideo.videoData.title;
      let videoURL = inforVideo.videoUrl;
      if (event.data == this.statusYT["playing"]) {
        console.log("Youtube playing");
        let time_start_view = this.updateTime(event.target.getCurrentTime());
        this.dynamicTime[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (event.data == this.statusYT["paused"]) {
        console.log("Youtube paused");
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        // console.log(time_end_view + " _ " + time_view);
        this.logDataAction(
          1,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      } else if (event.data == this.statusYT["ended"]) {
        console.log("Youtube ended");
        let time_end_view = this.updateTime(event.target.getCurrentTime());
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTime[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      }
    },
    updateTime(time) {
      time = Math.round(time);
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = time - hours * 3600 - minutes * 60;

      hours = hours < 10 ? "0" + hours : hours;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var checkTime = hours + ":" + minutes + ":" + seconds;
      return checkTime;
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
    logDataAction(
      status,
      videoTitle,
      videoURL,
      time_start_view,
      time_end_view,
      time_view
    ) {
      const req = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: window.document.activeElement.name,
        video_url: videoURL,
        video_title: videoTitle,
      };
      if (status == this.statusClick["start"]) {
        req.video_time_start_view = time_start_view;
      } else if (status == this.statusClick["paused"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
      } else if (status == this.statusClick["end"]) {
        req.video_time_end_view = time_end_view;
        req.video_time_view = time_view;
        req.video_end_view = true;
      }
      req.id_fix_page = this.$route.params.id;
      this.$store.dispatch("logActive", req);
    },

    //vimeo
    getVideoIDVimeo(src) {
      const regExp =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
      var match = src.match(regExp);
      const result = regExp.exec(src).slice(1);
      if (match) {
        let videoID = match[6];
        if (
          !src.includes("?") &&
          result[4] &&
          !result[4].includes("video") &&
          result[4].includes("/")
        ) {
          videoID = result[4].slice(0, -1);
        }
        return videoID;
      } else {
        console.log("not a vimeo url");
      }
    },

    async eventVimeoData(status, player, vimeoId) {
      let videoTitle = await player.getVideoTitle();
      let videoURL = await player.getVideoUrl();
      let name = "time_vimeo_" + vimeoId;
      let time = await player.getCurrentTime().then((time) => {
        return this.updateTime(time);
      });
      if (status == this.statusClick["start"]) {
        console.log("Vimeo start");
        let time_start_view = time;
        this.dynamicTimeVimeo[name] = time_start_view;
        this.logDataAction(
          0,
          videoTitle,
          videoURL,
          time_start_view,
          null,
          null
        );
      } else if (status == this.statusClick["paused"]) {
        console.log("Vimeo paused");
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        let stateVideo = await player.getEnded();
        if (!stateVideo)
          this.logDataAction(
            1,
            videoTitle,
            videoURL,
            null,
            time_end_view,
            time_view
          );
      } else if (status == this.statusClick["end"]) {
        console.log("Vimeo ended");
        let time_end_view = time;
        let time_view =
          this.getSecond(time_end_view) -
          this.getSecond(this.dynamicTimeVimeo[name]);
        this.logDataAction(
          2,
          videoTitle,
          videoURL,
          null,
          time_end_view,
          time_view
        );
      }
    },

    async viewFilePDF(file, idComponent) {
      this.$store.commit("set", ["clickOnPDF", true]);
      if (file.view === null) {
        const req = {
          shop_id: localStorage.getItem(Constants.SHOP_ID),
          url: window.document.activeElement.href
            ? window.document.activeElement.href
            : window.document.activeElement.baseURI,
          pdf_url: file.url,
        };
        const dataReturn = await this.$store.dispatch("countViewPDF", req);
        if (dataReturn.success) {
          file.view = dataReturn.data + 1;
        }
      } else {
        file.view = file.view + 1;
      }
      file.idComponent = idComponent;
      this.filePDFView = file;
      this.modalShow = !this.modalShow;
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },

    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.ql-editor.previewspage {
  min-height: unset !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0;
  background-color: #fff;
  border-color: #fff;
}
.link-home-page:hover {
  color: #aaa !important;
}
.text-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 22px;
  line-height: 30px;
  margin-top: 0;
}
.card-header {
  background-color: #fff;
  border-color: #fff;
}
.common-title {
  background-color: #fff;
  border: 1px solid black;
  padding: 5px;
}
.custom-button {
  border: 0px;
  background-color: rgb(255, 255, 255);
  overflow-anchor: none;
  border-top: 1px solid #e6e6e6;
}
.custom-button:hover {
  background-color: #e6e6e6 !important;
}
.custom-button:focus {
  border: 0px !important;
  box-shadow: none !important;
}
.page-static {
  .ql-editor {
    img {
      max-width: 100% !important;
    }
  }
}
.content-frame {
  width: 856.3px;
  margin: 0 auto;
  .ql-editor {
    padding: 12px 60px;
  }
}
</style>
